import axios from "@/api/restApi"

export default {
  async getHtmlBlobData(title) {
    const res = await axios.post("/api/html/select/specific/title", {
      params: { title },
    })
    return res.data[0]
  },

  async updateHtmlBlobData(title, content, lang = "한글") {
    let params = { title }
    let key = "content"
    switch (lang) {
      case "일어":
        key = "content_jp"
        break
      case "영어":
        key = "content_en"
        break
      default:
        break
    }
    params[key] = content

    const result = await axios.post("/api/html/update", {
      params,
    })
    return result.data.affectedRows ? true : false
  },

  async insertHtmlBlocData(title, content) {
    return await axios.post("/api/html/insert", {
      params: {
        title,
        content,
      },
    })
  },
}
